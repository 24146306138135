<script setup>
  const props = defineProps(["filter", "filtersStore"]);
  

const selectedSlug = ref(props.filtersStore.selected_filters[props.filter.slug]?.value?.slug || '');


const updateValue = (filter, row) => {
  props.filtersStore.updateValue(filter, row);
  selectedSlug.value = row.slug;
};
</script>

<template>
  
  <div class="px-3 mt-3">
    {{ filter.name }}
  </div>

    <div class="flex gap-1 px-3 mt-1 border-b border-b-slate-200 pb-3">
      <div v-for="item in filter.data_source" class="w-full">
        <w-button
      
          @click="updateValue(filter, item)"
          
          :class="[item.slug == selectedSlug ?'bg-indigo-800 text-white':'ring-1 ring-inset ring-current text-indigo-800']"
        >
          {{ item.label_value || item.value }}
        </w-button>
      </div>
    </div>
</template>